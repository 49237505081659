import React, { useCallback, useEffect, useState } from "react";
import styles from "./Modal.module.scss";
import close from "../../images/close-circle.svg";
import { useModal } from "../../store/modal";
import { Box } from "@mui/material";

const Modal = ({ isOpen, onClose, hideIcon, padRight, triggerClose, setTriggerClose }) => {
	const [modalActive, setModalActive] = useState(isOpen);
	const { data } = useModal((state) => state);

	console.log(data);
	useEffect(() => {
		if (isOpen) {
			setModalActive(isOpen);
		}
	}, [isOpen]);

	const handleClose = useCallback(() => {
		setModalActive(false);
		setTimeout(() => {
			onClose();
		}, 350);
	}, [onClose]);

	useEffect(() => {
		if (triggerClose) {
			handleClose();
		}
	}, [triggerClose, handleClose]);

	const modalStyle = () => {
		if (modalActive) {
			return `${styles.modalContent} ${styles.active}`;
		} else {
			return `${styles.modalContent}`;
		}
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Box sx={{ fontFamily: "Circular Std" }} className={styles.modalAlternate}>
			<div className={styles.modalContainer}>
				<div className={`${styles.buttonContainer}`}>
					<img src={close} onClick={handleClose} className={styles.button} alt="" />
				</div>
				<div className={modalStyle()}>
					<section className={` ${styles.content}`}>
						<div className={styles.exe}>
							<div className={styles.top}>
								<p className={styles.name}>{data?.name}</p>
								<p className={styles.role}>{data?.role}</p>
							</div>
							<div className={styles.line} />
							<div className={styles.info}>
								{data?.profile.map((itm, idx) => {
									return <p>{itm}</p>;
								})}
							</div>
						</div>
					</section>
				</div>
			</div>
		</Box>
	);
};

export default Modal;
