import React from "react";
import { constant } from "./constant";
import styles from "./Categories.module.css";
import { Box } from "@mui/material";
import zikhand from "../../../images/zik-hand.png";
import shadow from "../../../images/shadow.png";

const Categories = () => {
	return (
		<Box
			sx={{
				paddingTop: "2rem",
				paddingLeft: "5rem",
				paddingRight: "5rem",
				fontFamily: "Circular Std",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "bottom",
				backgroundSize: "100%",
				"@media (max-width: 639px)": {
					paddingInline: "24px",
				},
			}}
			className={styles.main}
		>
			<section className={styles.top}>
				<h2 className={styles.title}>{constant.title}</h2>
				<span className={styles.subtitle}>{constant.subtitle}</span>
			</section>

			<section className={styles.class}>
				<div className={styles.leftSide}>
					{constant.cards.map((itm) => {
						return (
							<div key={itm.id} className={styles.card}>
								<img src={itm.img} alt="" />
								<div className={styles.text}>
									<p className={styles.title2}>{itm.title}</p>
									<p className={styles.text2}>{itm.text}</p>
								</div>
							</div>
						);
					})}
				</div>
				<img src={zikhand} alt="img" className={styles.zikhand} />
			</section>
			<img src={shadow} alt="shadow" className={styles.shadow} />
		</Box>
	);
};

export default Categories;
