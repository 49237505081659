import React from "react";
import { Box } from "@mui/material";
import serve from "../../images/serve-place.png";
import styles from "./showcase.module.css";
import { constants } from "./constant";

const ShowCase = () => {
	return (
		<Box
			className={`${styles.main} h-full width-full sm:pt-[3rem]`}
			sx={{
				background: "rgba(232, 236, 227, 0.33)",
				paddingLeft: "5rem",
				paddingRight: "5rem",
				fontFamily: "Circular Std",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "bottom",
				backgroundSize: "100%",
				"@media (max-width: 639px)": {
					paddingInline: "24px",
				},
			}}
		>
			<img src={serve} alt="serve" className={styles.img} />

			<section className={styles.writeUp}>
				<p className={styles.title}>{constants.serveTitle}</p>
				<div className={styles.paraContainer}>
					{constants.servePara.map((itm, idx) => {
						return (
							<p key={idx} className={styles.para}>
								{itm}
							</p>
						);
					})}
				</div>
			</section>
		</Box>
	);
};

export default ShowCase;
