import zikapp from "../../../images/zik-app.png";
import zikcard from "../../../images/zik-card.png";
import zikpay from "../../../images/zik-pay.png";
import zikussd from "../../../images/zik-ussd.png";

export const constant = {
	title: "How ever you choose to bank, we’ve got you",
	subtitle: "The Zikora team is dedicated to building prosperity for our users",
	cards: [
		{
			id: 0,
            img:zikapp,
			title: "Zikora App",
			text: "Available on Apple & Play store",
		},
		{
			id: 1,
            img:zikcard,
			title: "Debit Card",
			text: "Available in-branch",
		},
		{
			id: 2,
            img:zikpay,
			title: "Zikora Pay",
			text: "Available in-branch",
		},
		{
			id: 3,
            img:zikussd,
			title: "USSD",
			text: "Dial *614*677#",
		},
	],
};
