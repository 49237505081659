import React, { useState } from "react";
import HomeMain from './main'
import GreenCard from './greenCard'
import LoansEmpower from './loans'
import Channels from './channels'
import Account from './accounts'
import DownloadApp from './downloadApp'
import Footer from '../../components/footer'
import Box from '@mui/material/Box'


const IndexHome = () => {
  const [openBanner, setOpenBanner] = useState(false)

  const handleClose = () => {
    setOpenBanner(false)
  }



  return (
    <>
      {/* {openBanner &&
        <div className=" flex flex-col justify-center items-center overlay">
          <div className="relative flex flex-col justify-start items-end mt-2">
            <div className="w-full p-2 lg:p-2 flex flex-row justify-end items-center bg-[green]">
              <p onClick={handleClose} className="cursor-pointer text-[white] font-medium mr-2">Close</p>
            </div>
            <div className="imgDiv">
              <a href="https://play.google.com/store/apps/details?id=com.clive.zikora">
                <img className="h-[500px] sm:h-[250px]" src={summerBanner} alt="" />
              </a>
            </div>
          </div>
        </div>
      } */}

      <Box sx={{
        '@media (min-width: 1700px)': {
          backgroundSize: '100%',
          maxWidth: '70vw',
          margin: 'auto'
        },
        '@media (min-width: 1600px) and (max-width: 1800px)': {
          backgroundSize: '100%',
          maxWidth: '90vw',
          margin: 'auto'
        },
      }}>
        <HomeMain />
        <div className={`${openBanner && 'hidden'}`}>
          <GreenCard />
          <LoansEmpower />
          <Channels />
          <Account />
          <DownloadApp />
          <Footer />
        </div>
      </Box>
    </>
  );
}

export default IndexHome;