import React from "react";
import Header from "../../components/header";
import contactMainPic from "../../images/contact-main-image1.png";
import aboutMobileMainPic from "../../small-images/about-mobile-main.png";
import aboutImg from "../../images/about-hero.svg";
import styles from "./main.module.css";
import { Box } from "@mui/material";
import Mission from "./mission";

const Main = () => {
	return (
		<Box
			sx={{
				background: "rgba(232, 236, 227, 0.33)",
				paddingTop: "2rem",
				fontFamily: "Circular Std",
				backgroundImage: `url(${contactMainPic})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				"@media (max-width: 639px)": {
					backgroundImage: `url(${aboutMobileMainPic})`,
				},
			}}
		>
			<div className={`${styles.head} px-[5rem]`}>
				<Header />
			</div>
			{/* <Box
				className={`${styles.main}`}
				sx={{ fontFamily: "Circular Std" }}
			>
				<img src={aboutImg} alt="about-hero" className={styles.img} />
				<div className={styles.content}>
					<p className={`${styles.aboutText}`}>About Zikora Bank</p>
					<p className={styles.info}>
						Zikora is a people-first bank, serving communities in Nigeria by providing
						access to reliable digital banking services.
					</p>
				</div>
			</Box> */}
			<Mission />
		</Box>
	);
};

export default Main;
