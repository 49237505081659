import React from "react";
import { Box } from "@mui/material";
import join from "../../images/join-zikora.png";
import styles from "./mission.module.css";
import { constants } from "./constant";
import CustomButton from "../../components/CustomButton";
import AppStoreImage from "../../images/apple-store.png";
import GooglePlay from "../../images/google-play.png";

const Mission = () => {
	return (
		<Box
			sx={{
				marginLeft: "5rem",
				gap: "100px",
				fontFamily: "Circular Std",
				"@media (max-width: 639px)": {
					margin: "3rem 1rem 0 1rem",
					gap: 0,
				},
			}}
			className={styles.main}
		>
			<section className={styles.writeUp}>
				<p className={styles.header}>{constants.joinTitle}</p>
				<p className={styles.content}>{constants.joinContent}</p>

				<div className={styles.links}>
					<CustomButton
						backgroundImageUrl={AppStoreImage}
						linkTo="https://apps.apple.com/us/app/zikora/id6444196255"
					/>
					<CustomButton
						backgroundImageUrl={GooglePlay}
						linkTo="https://play.google.com/store/apps/details?id=com.clive.zikora"
					/>
				</div>
			</section>
			<section className={styles.imgContainer}>
				<img src={join} alt="join" className={styles.img} />
			</section>
		</Box>
	);
};

export default Mission;
