import React from 'react'

const Divider = () => {
  return (
    <div style={{
        borderTop: '1px solid #bbbb'
    }}></div>
  )
}

export default Divider