import React, { useState } from "react";

import samplePic from "../../board-images/sample.jpg";
import olisaPic from "../../board-images/Okeke_low.png";
import peterEzePic from "../../board-images/Peter-eze.jpg";
import bernadinePic from "../../board-images/BernadineOkeke_low.png";
import chidozieAgpabuPic from "../../board-images/chidozieAgbapu.png";
import ikeOnyechiPic from "../../board-images/IkeOnyechi.png";
import oliverObiPic from "../../board-images/oliverObi.png";
import tonyIfeachoPic from "../../board-images/Ifeacho.png";
import matthewEzePic from "../../board-images/matthew-eze.jpg";
import chiomaPic from "../../board-images/chioma.jpg";
import emmanuelPic from "../../board-images/Emmanuel.jpg";
import josemariaPic from "../../board-images/josemariaEbisi.jpg";
import ijaraPic from "../../board-images/chigozieIjara.jpg";
import johnboscoPic from "../../board-images/Johnbosco-Okolocha.jpg";
import kaluPic from "../../board-images/Kalu-Nnachi.jpg";
import styles from "./directors.module.css";

import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { Box } from "@mui/material";
import { readMore } from "./constant";
import Modal from "../../components/Modal/Modal";
import { useModal } from "../../store/modal";

const Directors = () => {
	const { isOpen, setData, onOpen, onClose } = useModal((state) => state);
	const [directorIndex, setDirectorIndex] = useState(0);
	const [managementIndex, setManagementIndex] = useState(0);

	const StaffTemplate = ({ pic = samplePic, alt, title, post, children, readmore }) => {
		return (
			<div
				className={`${styles.card} ${
					alt && styles.cardAlt
				} flex flex-col sm:items-center gap-5 sm:gap-1 w-[400px] sm:w-full`}
				onClick={() => {
					onOpen();
					setData(readmore);
				}}
			>
				<div
					className={`${styles.detailsContainer} w-full sm:w-[85%] flex flex-col justify-center items-center `}
				>
					<img src={pic} alt="" className={styles.imgPeople} />
					<div className={`${styles.detailsContent}`}>
						<div className={styles.details}>
							<p className={styles.name}>{title}</p>
							<p className={styles.post}>{post}</p>
						</div>
						<p className={styles.read}>Read more</p>
					</div>
				</div>
			</div>
		);
	};

	const directorsList = [
		<GroupImage>
			<StaffTemplate
				title={"Olisa Okeke"}
				post={"Chairman"}
				pic={olisaPic}
				readmore={readMore.olisa}
			></StaffTemplate>

			<StaffTemplate
				title={"Bernadine Okeke"}
				post={"Director"}
				pic={bernadinePic}
				readmore={readMore.bernadine}
			></StaffTemplate>

			<StaffTemplate
				title={"Peter Eze"}
				post={"Director"}
				pic={peterEzePic}
				readmore={readMore.Peter}
			></StaffTemplate>

			<StaffTemplate
				title={"Oliver Obi"}
				post={"Director"}
				pic={oliverObiPic}
				readmore={readMore.oliver}
			></StaffTemplate>
		</GroupImage>,

		<GroupImage>
			<StaffTemplate
				title={"Chidozie Agbapu"}
				post={"Director"}
				pic={chidozieAgpabuPic}
				readmore={readMore.chidozie}
			></StaffTemplate>

			<StaffTemplate
				title={"Sir. Ike Onyechi"}
				post={"Director"}
				pic={ikeOnyechiPic}
				readmore={readMore.onyechi}
			></StaffTemplate>
			<StaffTemplate
				title={"Tony Ifeacho"}
				post={"Director"}
				pic={tonyIfeachoPic}
				readmore={readMore.tony}
			></StaffTemplate>
		</GroupImage>,
	];

	const directorsListMobile = [
		<StaffTemplate
			title={"Olisa Okeke"}
			post={"Chairman"}
			pic={olisaPic}
			readmore={readMore.olisa}
		></StaffTemplate>,

		<StaffTemplate
			title={"Bernadine Okeke"}
			post={"Director"}
			pic={bernadinePic}
			readmore={readMore.bernadine}
		></StaffTemplate>,

		<StaffTemplate
			title={"Peter Eze"}
			post={"Director"}
			pic={peterEzePic}
			readmore={readMore.Peter}
		></StaffTemplate>,

		<StaffTemplate
			title={"Oliver Obi"}
			post={"Director"}
			pic={oliverObiPic}
			readmore={readMore.oliver}
		></StaffTemplate>,

		<StaffTemplate
			title={"Chidozie Agbapu"}
			post={"Director"}
			pic={chidozieAgpabuPic}
			readmore={readMore.chidozie}
		></StaffTemplate>,

		<StaffTemplate
			title={"Sir. Ike Onyechi"}
			post={"Director"}
			pic={ikeOnyechiPic}
			readmore={readMore.onyechi}
		></StaffTemplate>,

		<StaffTemplate
			title={"Tony Ifeacho"}
			post={"Director"}
			pic={tonyIfeachoPic}
			readmore={readMore.tony}
		></StaffTemplate>,
	];

	const managementList = [
		<GroupImage>
			<StaffTemplate
				alt={true}
				title={"Kalu Nnachi"}
				post={"Chief Executive Officer"}
				readmore={readMore.kalu}
				pic={kaluPic}
			></StaffTemplate>
			<StaffTemplate
				alt={true}
				title={"Matthew Eze"}
				post={"Head, Credit and Marketing"}
				readmore={readMore.matthew}
				pic={matthewEzePic}
			></StaffTemplate>
			<StaffTemplate
				alt={true}
				title={"Emmanuel Ikogho"}
				post={"Head, Banking Operations"}
				readmore={readMore.emmanuel}
				pic={emmanuelPic}
			></StaffTemplate>
			<StaffTemplate
				alt={true}
				title={"Chioma Onuoha"}
				post={"Head, Digital Banking"}
				readmore={readMore.chioma}
				pic={chiomaPic}
			></StaffTemplate>
		</GroupImage>,

		<GroupImage>
			<StaffTemplate
				alt={true}
				title={"Chigozie Ijara"}
				post={"Head, Information Technology"}
				readmore={readMore.chigozie}
				pic={ijaraPic}
			></StaffTemplate>
			<StaffTemplate
				alt={true}
				title={"Josemaria Ebisi"}
				post={"Financial Controller"}
				readmore={readMore.josemaria}
				pic={josemariaPic}
			></StaffTemplate>

			<StaffTemplate
				alt={true}
				title={"Johnbosco Okolocha"}
				post={"Internal Auditor"}
				readmore={readMore.johnbosco}
				pic={johnboscoPic}
			></StaffTemplate>
		</GroupImage>,
	];

	const managementListMobile = [
		<StaffTemplate
			alt={true}
			title={"Kalu Nnachi"}
			post={"Chief Executive Officer"}
			readmore={readMore.kalu}
			pic={kaluPic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Matthew Eze"}
			post={"Head, Credit and Marketing"}
			readmore={readMore.matthew}
			pic={matthewEzePic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Emmanuel Ikogho"}
			post={"Head, Banking Operations"}
			readmore={readMore.emmanuel}
			pic={emmanuelPic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Chioma Onuoha"}
			post={"Head, Digital Banking"}
			readmore={readMore.chioma}
			pic={chiomaPic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Chigozie Ijara"}
			post={"Head, Information Technology"}
			readmore={readMore.chigozie}
			pic={ijaraPic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Josemaria Ebisi"}
			post={"Financial Controller"}
			readmore={readMore.josemaria}
			pic={josemariaPic}
		></StaffTemplate>,

		<StaffTemplate
			alt={true}
			title={"Johnbosco Okolocha"}
			post={"Internal Auditor"}
			readmore={readMore.johnbosco}
			pic={johnboscoPic}
		></StaffTemplate>,
	];

	return (
		<div className={styles.contents}>
			<div
				className={`${styles.Directors} flex flex-col my-16 items-center gap-3 px-16 sm:px-3`}
				style={{ background: "#F8F8F8" }}
			>
				<div className="sm:hidden flex flex-col gap-2 w-full">
					<Carosel
						handleLeft={() => {
							if (directorIndex > 0) setDirectorIndex(directorIndex - 1);
						}}
						handleRight={() => {
							if (directorIndex < directorsList.length - 1)
								setDirectorIndex(directorIndex + 1);
						}}
						data={directorsList}
						index={directorIndex}
						title={"Board of directors"}
						setIndex={setDirectorIndex}
					/>
					{directorsList[directorIndex]}
				</div>

				<div className={`${styles.Carosel2} hidden w-[100%] sm:flex flex-col gap-2`}>
					<Carosel
						handleLeft={() => {
							if (directorIndex > 0) setDirectorIndex(directorIndex - 1);
						}}
						handleRight={() => {
							if (directorIndex < directorsListMobile.length - 1)
								setDirectorIndex(directorIndex + 1);
						}}
						data={directorsListMobile}
						index={directorIndex}
						setIndex={setDirectorIndex}
						title={"Board of directors"}
					/>
					{directorsListMobile[directorIndex]}
				</div>
			</div>

			<div
				className={`${styles.Directors} flex flex-col my-16 items-center gap-3 px-16 sm:px-3`}
			>
				<div className="sm:hidden flex flex-col gap-2 w-full">
					<Carosel
						handleLeft={() => {
							if (managementIndex > 0) setManagementIndex(managementIndex - 1);
						}}
						handleRight={() => {
							if (managementIndex < managementList.length - 1)
								setManagementIndex(managementIndex + 1);
						}}
						data={managementList}
						index={managementIndex}
						setIndex={setManagementIndex}
						title={"Management team"}
					/>
					{managementList[managementIndex]}
				</div>

				<div
					className={`${styles.Carosel2} hidden w-[100%] sm:flex flex-col sm:flex-col-reverse gap-2`}
				>
					<Carosel
						handleLeft={() => {
							if (managementIndex > 0) setManagementIndex(managementIndex - 1);
						}}
						handleRight={() => {
							if (managementIndex < managementListMobile.length - 1)
								setManagementIndex(managementIndex + 1);
						}}
						data={managementListMobile}
						index={managementIndex}
						setIndex={setManagementIndex}
						title={"Management team"}
					/>
					{managementListMobile[managementIndex]}
				</div>
			</div>
			<Modal isOpen={isOpen} onClose={onClose} />
		</div>
	);
};

export default Directors;

const ClickIcon = ({ Icon, handleClick = () => {} }) => {
	return (
		<div
			onClick={handleClick}
			className="px-2 py-2 sm:px-3 sm:py-3 border-1 hover:border-[#66A681] hover:bg-[#66A681] hover:text-[white] cursor-pointer rounded-full"
		>
			<Icon size={18} />
		</div>
	);
};

const GroupImage = ({ children }) => {
	return <div className={`${styles.groupImage} w-[100%]`}>{children}</div>;
};

const Carosel = ({ handleLeft, handleRight, title }) => {
	return (
		<Box
			sx={{
				fontFamily: "Circular Std",
			}}
		>
			<div className={styles.carosel}>
				<div className={styles.heading}>
					<p className={styles.title}>{title}</p>
					<p className={styles.subtitle}>
						{"The Zikora team is dedicated to building prosperity for our users"}
					</p>
				</div>
				<div className="flex gap-5 ">
					<ClickIcon handleClick={handleLeft} Icon={MdOutlineArrowBackIosNew} />
					<ClickIcon handleClick={handleRight} Icon={MdOutlineArrowForwardIos} />
				</div>
			</div>
		</Box>
	);
};
