export const constants = {
	joinTitle: "We will show you how banking should be done.",
	joinContent: `At Zikora Bank, we’re committed to driving positive change in your life and the economy of our communities. 
    That's why we offer financial services and products designed to accelerate growth and improvement, tailored specifically 
    to meet your needs and enhance community development.`,
	serveTitle: "We are here to serve you!",
	servePara: [
		`We at Zikora Microfinance Bank are aware that handling your money can 
        occasionally seem overwhelming. We are here to support you as a reliable 
        (Bank) friend because of this. We're (available) here to (assist) help 
        you (on every step you take in your endeavor) every step of the way, 
        whether you're a small business owner hoping to expand or (are you) 
        someone else looking for better financial solutions.`,
		`We are committed to facilitating (Micro and Macro loans) easy and universal 
        access to banking. Our (Customer-oriented) friendly staff is constantly 
        available to provide you with the assistance you require, along with 
        (best practices) honest guidance and a smile. Together, let's create a 
        (New World of Banking) more promising financial future!
`,
	],
};

export const readMore = {
	olisa: {
		name: "Olisa Okeke",
		role: "Chairman",
		profile: [
			`Chief Olisa Okeke obtained his BSBA (Accountancy) and MBA degrees from the Suffolk University, Boston, 
            USA. An experienced qualified accountant and ex-banker, Chief Okeke began his banking career at the State Street Bank Boston, Mass. U.S.A as an analyst before moving over to the defunct Arthur Anderson USA and Nigeria. He later joined the International Merchant Bank (IMB), Nigeria in 1987 where
             he rose to the position of Senior Manager before joining Ivory Merchant Bank as a General Manager, Corporate Banking.`,
			`Chief Okeke’s professional memberships include member of the American Institute of Certified Public Accountants 
            (CPA) and Fellow of the Nigerian Institute of Chartered Accountants (FCA).
    `,
			`A real estate development practitioner, he is the Chairman of Cardinal Developers and Trust Limited, Lagos
     which has developed a number of notable properties in Lagos and Port Harcourt. `,
			`Chief Okeke is married with children.`,
		],
	},
	bernadine: {
		name: "Bernadine Okeke",
		role: "Director",
		profile: [
			`Mrs. Okeke graduated from Hampton University, Virginia, USA in 1978 with Bachelor 
            degree in accounting and from Suffolk University, Boston, USA with an MBA degree in 1981. 
            She started her banking career at the Bank of New England, Boston in the USA in 1981.
    `,
			`In Nigeria, she has worked in a number of banks and other companies 
    including Nigerian American Merchant Bank, Victory Merchant Bank, Seven-Up 
    Bottling Company and First Bank of Nigeria where she rose to the level of Group Executive.  
    `,
			`An experienced ex-banker, Mrs. Okeke is a director 
            in Cardinal Developers and Trust Limited, Lagos. She is married with children.
    `,
		],
	},
	Peter: {
		name: "Peter Eze",
		role: "Director",
		profile: [
			`Peter Eze obtained a B.Sc. degree in Economics from the University of Nigeria, 
            Nsukka in 1985 and an MBA degree in Finance from the same university in 1989. 
            He started his banking career with Chartered Bank Plc, now Stanbic IBTC Bank, 
            in December 1990 and later moved to Zenith Bank Plc where he rose to the position of 
            Deputy General Manager and Regional Manager before retiring in 2012.
    `,
			`Peter had attended a number of banking and management courses locally 
    and abroad including the Lagos Business School and Management Advisory Services Inc., Seattle, USA.
    `,
			`He was involved in the restructuring and repositioning of Ndiolu Micro Finance Bank Limited, 
    owned by the workforce of Anambra State Government in his home State of Anambra as the 
    Managing Director/Chief Executive Officer of the bank.
    `,
			`Mr. Eze is married with children.`,
		],
	},
	oliver: {
		name: "Oliver Obi",
		role: "Director",
		profile: [
			`Mr. Obi graduated from the University of Nigeria, Nsukka with a B.Sc. 
            (Hons.) Food Science/Tech in 1989 and an MBA, Banking and Finance from the 
            Enugu State University of Technology in 1999.
    `,
			`He started his banking career in Orient Bank in 1991 and later worked with other banks including All States Trust Bank, Citizens International Bank, Fortune International Bank as well as UBA Plc and Oceanic Bank where he rose to the position of Senior Manager.
    `,
			`
    Mr. Obi is the Managing Director/CEO of Janiking & Degreasers Limited, Lagos (Facility Managers). He is married with children.
    `,
		],
	},
	chidozie: {
		name: "Chidozie Agbapu",
		role: "Director",
		profile: [
			`Mr. Chidozie Agbapu graduated with a BSc Economics degree from the University of Nigeria in 1986 and an M.Sc. in Banking & Finance, from University of Lagos in 1995. He also attended the Advanced Management Program (AMP14) 2000 at Lagos Business School (LBS).
    `,
			`A licensed stockbroker, Mr. Agbapu is the founder and co-CEO of Planet Capital Limited (Member of The Nigerian Stock Exchange). He had previously worked in some banking and financial services institutions including New Nigeria Bank, Financial Mortgage (Savings & Loans) Limited, Prominent Securities Limited, Equator Finance & Securities Limited and Emerging Capital Limited (Member of The Nigerian Stock Exchange) as the CEO.
    `,
			`Mr. Agbapu has been on the board of a number of companies and institutions which include National Council member, The Nigerian Stock Exchange, Director, Fidelity Bank Plc (Present), Coral Properties Ltd (subsidiary of NSE), MTI Nigeria Plc and MTI Limited, Accra Ghana, Central Securities Clearing Systems (CSCS).
    `,
			`Mr. Agbapu is married with children.
    `,
		],
	},
	onyechi: {
		name: "Sir. Ike Onyechi",
		role: "Director",
		profile: [
			`Sir Ike Onyechi obtained his Bachelor of Pharmacy degree from the University of Nigeria, Nsukka in 1980 and an MBA from the Enugu State University of Technology, Enugu as well as the Advanced Management Programme certificate from the Lagos Business School, LBS.
    `,
			`He had worked in Nigerian Hoechst Limited (NGC) before retiring. He is the owner and Chief Executive Officer of Alpha Pharmacy Limited, a distributor of pharmaceutical and ethical products. He is also the Chairman of Clement Industries Limited, among others.
    `,
			`Sir Onyechi is a Fellow of the Pharmaceutical Society of Nigeria, West Africa Post Graduate College of Pharmacists, the Nigeria Academy of Pharmacy, Courier and Logistics Management Institute. He is a member of the International Pharmaceutical Foundation of Nigeria and a board member of Neimeth Pharmaceuticals International Plc, Lagos.
    `,
			`A Knight of St. Christopher, Sir Onyechi is married with children.
    `,
		],
	},
	tony: {
		name: "Tony Ifeacho",
		role: "Director",
		profile: [
			`Tony Ifeacho has over 15 years banking experience which he gained at Fidelity Bank Plc, Diamond Bank Plc and Broad Bank of Nigeria Ltd. 
    `,
			`A graduate of Civil Engineering with an MBA, he has attended various senior management and risk management programmes and seminars at Lagos Business School; Euromoney, U.K.; IESE, Barcelona; Risk Magazine, London; and INSEAD, France. 
    `,
			`At Diamond Bank where he worked for over 11 years, he held senior management positions which include Head of Corporate Banking Group and Head of Risk Management/Credit Division. 
    `,
			`Prior to that, he had trained and worked in Etteh Aro & Partners, Ibadan, a reputable Structural and Civil Engineering firm where he handled a number of design and supervision assignments.
    `,
			`He is the Managing Director/CEO of Drexell Capital Management Limited, a Financial Consultancy and Human Capital Development Company in Lagos. He was also the Chairman of NovaMBL Asset Management Limited, a subsidiary of Nova Merchant Bank Limited, Lagos.
    `,
			`Mr. Ifeacho is married with children.`,
		],
	},
	kalu: {
		name: "Kalu Nnachi",
		role: "Chief Executive Officer",
		profile: [
			`Kalu is an experienced Commercial and Certified Microfinance Banker, a Marketing Executive, a Financial & Investment Analysis Enthusiast and a Business Leader. He has over a decade of Banking experience which cut across Banking Operations, Retail Banking, Customer Service and Marketing. He began his career as Sales Representative in C’D Demerics Pharmaceuticals Ltd and later on moved to Zenith Bank Plc. After Zenith, he founded LBM Trading & Logistics Company where he served as the Founder/CEO for One (1) year before Co-founding and serving as the Managing Director/CEO of Maestro Magnet Limited – a Non-Bank Financial Institution (NBFI) in 2022. He has attended several trainings sponsored by Zenith Bank Plc on Sales & Marketing, Leadership and Customer Support. He is happily married and an ordained deacon.
    `,
		],
	},
	matthew: {
		name: "Matthew Eze",
		role: "Head, Credit and Marketing",
		profile: [
			`Matthew Eze is a Graduate of Accounting and a member of the Chartered Institute of Bankers of Nigeria (CIBN). He has over fourteen cognate years of experience in Banking. He worked with Forward Microfinance Bank Limited for 6 years and rose to the level of Acting Head, Credit and Marketing. He joined Accion Microfinance Bank Limited in 2016 and worked in Internal Control as Branch Controller for 2 years and moved to Commercial Department as a Supervisor and later became the Branch Manager. 
    He had many recognitions and Awards from Accion Microfinance Bank Limited and was exceptional promoted by the Management Team for being the Best Manager in the year 2020.
    `,
		],
	},
	chigozie: {
		name: "Chigozie Ijara",
		role: "Head, Information Technology",
		profile: [
			`Chigozie Ijara is a computer science graduate and technology analyst with expertise in security and infrastructure, and familiarity with system programming languages such as Java SE and Python. He has more than seven years of expertise in general IT support.
    Chigozie has participated in numerous seminars focusing on information technology security, cybersecurity, classified banking IT support, financial product development, and liquidity management.
    His professional background includes roles as a Cash Service Representative at Diamond Bank Plc and later Access Bank and IT Support at Edubridge Consultants Limited.
    `,
		],
	},
	emmanuel: {
		name: "Emmanuel Ikogho",
		role: "Head, Banking Operations",
		profile: [
			`Emmanuel Ikogho is a Graduate of Accounting and a member of the Institute of Chartered Accountants of Nigeria (ICAN). He has sixteen years work experience spanning Banking, Telecommunications, and Estate Development sectors.
    `,
		],
	},
	chioma: {
		name: "Chioma Onuoha",
		role: "Head, Digital Banking",
		profile: [
			`Chioma Onuoha is a Graduate of Computer Science with special interests in Digital Marketing and Web Development using Wordpress. Chioma has over nine cognate years of experience in IT support and Digital Marketing. She had undergone training in Oracle 11g, Digital Marketing and Project Management Professional.
    She worked with Diamond Bank Plc and later Access Bank Limited across Northern Region as Cash Service Representative and IT Support/Digital Marketer for Flexisaf Edu. Soft LTD, Grandville Medical and Laser Hospital as Product Manager/Digital Marketer. She also worked at Protection Plus Services as IT Admin/Support.
    `,
		],
	},
	josemaria: {
		name: "Josemaria Ebisi",
		role: "Financial Controller",
		profile: [
			`Josemaria Kenechukwu Ebisi obtained B.Sc. in Accountancy from University of Nigeria, Nsukka. He holds certification on Project Management Proficiency and Advanced Quickbooks Accounting. He equally holds special analytical skill on data interpretation and building of financial model. 
    Josemaria have over eight years work experience spanning: Banking, Hospitality sector, and State Internal Board of Revenue.
    `,
		],
	},
	johnbosco: {
		name: "Johnbosco Okolocha",
		role: "Internal Auditor",
		profile: [
			`Johnbosco Okolocha obtained BSc in Economics from Chukwuemeka Odumegwu Ojukwu University. He is a member of the Chartered Institute of Bankers of Nigeria (CIBN). He has over eleven cognate years of experience in Internal Audit/Control and Compliance.
    `,
		],
	},
};
